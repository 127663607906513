.app {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.wechat-warning {
  background-color: #ff6b6b;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.copy-link-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.copy-link-button:hover {
  background-color: #45a049;
}

.bookmark-reminder {
  background-color: #ff6b6b;
  color: white;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
  border-radius: 5px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.bookmark-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.bookmark-button:hover {
  background-color: #45a049;
}

.bookmark-icon {
  margin-right: 10px;
}

.bookmark-message {
  margin: 15px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  padding: 3px;
  border-radius: 5px;
  margin: 20px 0;
}

.progress-bar {
  height: 20px;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}