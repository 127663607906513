body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

h1, h2 {
  margin-bottom: 20px;
}

.blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.favorite-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 20px;
}

.progress-bar {
  height: 30px;
  background-color: #4CAF50;
  transition: width 0.5s ease-in-out;
}